.home{
  padding-left: 2vw;
  padding-right: 2vw;
  background-color: black;
  background-image: url("../../public/assets/images/scifibg.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  overflow-y: scroll;
  height: 100vh;
  @media screen and (max-width: 768px) {
    background-position: center;
  }
}

body {
  min-width: 100%;
  overflow-y: hidden;
}

.dropdown-div1{
  margin-right: 2vw;
  margin-left: 2vw;
  position: relative;
  padding-top: 16vh;
}

.dropdown-select{
  font-family: 'Orbitron', sans-serif;
  margin-left: auto;
  margin-right: auto;
  display: block;
  background-color: rgba(35, 40, 48, 0.87);
  border: none;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  appearance: none;
  height: 3rem;
  padding-left: 1rem;
  padding-right: 2.5rem;
  font-size: 1rem;
  line-height: 2;
  min-height: 3rem;
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  border-radius: var(--rounded-btn,.5rem);
  //for down arrow
  background-image: linear-gradient(45deg,transparent 50%,currentColor 50%),linear-gradient(135deg,currentColor 50%,transparent 50%);
  background-position: calc(100% - 20px) calc(1px + 50%),calc(100% - 16px) calc(1px + 50%);
  background-size: 4px 4px,4px 4px;
  background-repeat: no-repeat;
}

.load-button{
  font-family: 'Orbitron', sans-serif;
  margin-top: 2vh;
  display: block;
  margin-right: auto;
  margin-left: auto;
  height: 3rem;
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: var(--rounded-btn,.5rem);
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  color: rgba(0, 0, 0, 0.8);
  font-size: 1rem;
  font-weight: 400;
}

.hover-effect{
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  &:hover {
    transform: scale(1.05);
  }
}

//dropdowninput.jsx
.dropdown-div2{
  margin-bottom: 2vh;
}

//datalist.jsx
.datalist-button{
  font-family: 'Orbitron', sans-serif;
  margin-top: 2vh;
  display: block;
  margin-right: auto;
  margin-left: auto;
  height: 3rem;
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: var(--rounded-btn,.5rem);
  background-color: rgba(35, 40, 48, 0.87);
  border: none;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  font-weight: 400;
}
.subjects-text{
  font-family: 'Orbitron', sans-serif;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.2rem;
  font-weight: 600;
}

.rotate{
  margin-top: -1vh;
  transform: rotate(90deg);
}

.insta-handle-bar{
  margin-top: 2vh;
  font-family: 'Orbitron', sans-serif;
  color: white;
  vertical-align: top;
  display: inline-block;
  text-align: center;
}

.insta-username{
  color: white;
  text-decoration: none;
  display: block;
}

.insta-follow-krlena{
  font-family: 'Orbitron', sans-serif;
  font-size: 0.4rem;
}

//for bottom corner form link
.form-link{
  position: fixed;
  bottom: 0;
  right: 0;
  color: white;
  margin-right: 3vw;
  margin-bottom: 2vw;
}